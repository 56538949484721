import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';

import { WorkspaceConfig } from '../assets/config';
import { i18n } from '../assets/i18n';
import {
  AuthenticationService,
  StatusVerifierService,
  RoleVerifierService,
} from '../services';
import { QueryKeys } from '../models';

const AboutView = () =>
  import(
    /* webpackChunkName: "administrator" */ '../views/about/about-view.vue'
  );

const AboutBmdView = () =>
  import(
    /* webpackChunkName: "administrator" */ '../views/about/about-bmd-view.vue'
  );
const ActionsView = () =>
  import(
    /* webpackChunkName: "administrator" */ '../views/actions/actions-view.vue'
  );
const AdministratorDetailView = () =>
  import(
    /* webpackChunkName: "administrator" */ '../views/administrator/administrator-detail-view.vue'
  );
const AdministratorNewView = () =>
  import(
    /* webpackChunkName: "administrator" */ '../views/administrator/administrator-new-view.vue'
  );
const UserUpdateInfoView = () =>
  import(
    /* webpackChunkName: "administrator" */ '../views/administrator/administrator-update-info-view.vue'
  );
const UserEditConnectionView = () =>
  import(
    /* webpackChunkName: "administrator" */ '../views/administrator/user-edit-connection-view.vue'
  );
const AdministratorView = () =>
  import(
    /* webpackChunkName: "administrator" */ '../views/administrator/administrator-view.vue'
  );

const InstitutionAddSurgeon = () =>
  import(
    /* webpackChunkName: "institution" */ '../views/institution/institution-add-surgeon.vue'
  );
const InstitutionDetailView = () =>
  import(
    /* webpackChunkName: "institution" */ '../views/institution/institution-detail-view.vue'
  );

const InstitutionNewView = () =>
  import(
    /* webpackChunkName: "institution" */ '../views/institution/institution-new-view.vue'
  );
const InstitutionUpdateAieView = () =>
  import(
    /* webpackChunkName: "institution" */ '../views/institution/institution-update-aie-view.vue'
  );
const InstitutionUpdateInfoView = () =>
  import(
    /* webpackChunkName: "institution" */ '../views/institution/institution-update-info-view.vue'
  );
const InstitutionUpdateSystemView = () =>
  import(
    /* webpackChunkName: "institution" */ '../views/institution/institution-update-system-view.vue'
  );
const InstitutionView = () =>
  import(
    /* webpackChunkName: "institution" */ '../views/institution/institution-view.vue'
  );

import NavigationBarVerticalView from '../views/navigation-bar/navigation-bar-vertical-view.vue';
import StatusErrorView from '../views/status-error/status-error-view.vue';
import WorklistView from '../views/work/worklist-view.vue';
import { useUserTierPermissions } from '../composables/useUserTierPermissions';

const UserProfileUpdateEmailView = () =>
  import(
    /* webpackChunkName: "user" */ '../views/profile/user-profile-update-email-view.vue'
  );
const UserProfileUpdateTechnicianView = () =>
  import(
    /* webpackChunkName: "user" */ '../views/profile/user-profile-update-technician-view.vue'
  );
const UserProfileUpdatePasswordView = () =>
  import(
    /* webpackChunkName: "user" */ '../views/profile/user-profile-update-password-view.vue'
  );
const UserProfileUpdatePhoneView = () =>
  import(
    /* webpackChunkName: "user" */ '../views/profile/user-profile-update-phone-view.vue'
  );
const UserProfileUpdateShippingAddressView = () =>
  import(
    /* webpackChunkName: "user" */ '../views/profile/user-profile-update-shipping-address-view.vue'
  );
const UserProfileUpdateStateView = () =>
  import(
    /* webpackChunkName: "user" */ '../views/profile/user-profile-update-states-view.vue'
  );
const UserProfilePanelView = () =>
  import(
    /* webpackChunkName: "user" */ '../views/profile/user-profile-view.vue'
  );

const PatientDetailsView = () =>
  import(
    /* webpackChunkName: "patient" */ '../views/patient/patient-detail-view.vue'
  );
const PatientListView = () =>
  import(
    /* webpackChunkName: "patient" */ '../views/patient/patient-list-view.vue'
  );

const CaseListManagerView = () =>
  import(
    /* webpackChunkName: "patient" */ '../views/3d-services/case-list-manager.vue'
  );

const CaseListTechnicianView = () =>
  import(
    /* webpackChunkName: "patient" */ '../views/3d-services/case-list-technician.vue'
  );

const CaseHistoryView = () =>
  import(
    /* webpackChunkName: "cases" */ '../views/3d-services/case-history.vue'
  );

const CaseCalendarView = () =>
  import(
    /* webpackChunkName: "calendar" */ '../views/case-calendar/case-calendar-view.vue'
  );

const CaselistAnalyticsView = () =>
  import(
    /* webpackChunkName: "analytics" */ '../views/3d-services/analytics-view.vue'
  );

const ComingSoon = () =>
  import(
    /* webpackChunkName: "analytics" */ '../views/coming-soon/coming-soon.vue'
  );

const AnalyticsView = () =>
  import(
    /* webpackChunkName: "analytics" */ '../views/analytics/analytics-view.vue'
  );

const EULAView = () =>
  import(/* webpackChunkName: "eula" */ '../views/eula/eula-view.vue');

const MobileExamsView = () =>
  import(
    /* webpackChunkName: "analytics" */ '../views/mobile/mobile-exams-view.vue'
  );

const MobileStrategyView = () =>
  import(
    /* webpackChunkName: "analytics" */ '../views/mobile/mobile-strategy-view.vue'
  );

const RodOrderingView = () =>
  import(
    /* webpackChunkName: "analytics" */ '../views/rod-ordering/rod-list-view.vue'
  );

enum HomePage {
  ADMIN = 'users',
  STATUS_ERROR = 'status-error',
  USER = 'worklist',
}

const adminVerification = (landing = HomePage.USER) => {
  return RoleVerifierService.isAdmin() ? true : { name: landing };
};

const APP_NAME = WorkspaceConfig.instance().appName();

export function getUsersHomepage() {
  if (RoleVerifierService.isAdmin()) {
    return { name: HomePage.ADMIN };
  }
  if (RoleVerifierService.isAdmin()) {
    return { name: 'users' };
  }
  if (RoleVerifierService.isInstitutionUser()) {
    return { name: 'patient-list' };
  }
  if (
    RoleVerifierService.isTechnicianManager() ||
    RoleVerifierService.isTechnician()
  ) {
    return { name: '3d-services-list-default' };
  }
  if (RoleVerifierService.isRodManufacturer()) {
    return { name: 'rod-ordering' };
  }
  if (RoleVerifierService.isUserIdentified()) {
    return true;
  }

  return false;
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'worklist',
    components: {
      default: WorklistView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('worklist.listPageTitle'),
    },

    beforeEnter: () => {
      return getUsersHomepage();
    },
  },
  {
    path: '/actions',
    name: 'actions',
    components: {
      default: ActionsView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('actions.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      const userTierPermissions = useUserTierPermissions();

      if (showComingSoonPage()) {
        return { name: 'coming-soon' };
      }

      if (RoleVerifierService.isInstitutionUser()) {
        if (userTierPermissions.isActionsMenuBarDisabled.value) {
          return getUsersHomepage();
        }
        return true;
      } else {
        return getUsersHomepage();
      }
    },
  },
  {
    path: '/case/analytics',
    name: 'case-analytics',
    components: {
      default: CaselistAnalyticsView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('analytics.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      if (showComingSoonPage()) {
        return { name: 'coming-soon' };
      }
      if (RoleVerifierService.isTechnicianManager()) {
        return true;
      }
      return false;
    },
  },
  {
    path: '/eula',
    name: 'eula',
    components: {
      default: EULAView,
    },
    meta: {
      title: i18n.global.t('eula.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      if (StatusVerifierService.hasAcceptedEULA()) {
        return getUsersHomepage();
      }
      return true;
    },
  },
  {
    path: '/institutions',
    name: 'institutions',
    components: {
      default: InstitutionView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('institutions.pageTitle'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/new-institution',
    name: 'new-institution',
    components: {
      default: InstitutionNewView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('institutions.pageTitleNew'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/detail-institution/:id',
    name: 'detail-institution',
    components: {
      default: InstitutionDetailView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('institutions.detailPageTitle'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/detail-institution-add-surgeon/:id',
    name: 'detail-institution-add-surgeon',
    components: {
      default: InstitutionAddSurgeon,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('institutions.detailPageTitle'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/update-institution/:id/aie-configuration',
    name: 'update-institution-aie',
    components: {
      default: InstitutionUpdateAieView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('institutions.updAiePageTitle'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/update-institution/:id/information',
    name: 'update-institution-info',
    components: {
      default: InstitutionUpdateInfoView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('institutions.updInfoPageTitle'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/update-institution/:id/system',
    name: 'update-institution-system',
    components: {
      default: InstitutionUpdateSystemView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('institutions.updSysPageTitle'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/users',
    name: 'users',
    components: {
      default: AdministratorView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('administrators.pageTitleList'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/users/new',
    name: 'new-user',
    components: {
      default: AdministratorNewView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('administrators.pageTitleNew'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/users/detail/:id',
    name: 'detail-user',
    components: {
      default: AdministratorDetailView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('administrators.detailPageTitle'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/users/edit/:id',
    name: 'update-administrator-info',
    components: {
      default: UserUpdateInfoView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('administrators.updInfoPageTitle'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/users/connection/:id',
    name: 'user-edit-connection',
    components: {
      default: UserEditConnectionView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('connections.updConnectionsPageTitle'),
    },
    beforeEnter: () => adminVerification(),
  },
  {
    path: '/info',
    name: 'info',
    components: {
      default: AboutView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('about.pageTitleInfo'),
      backgroundColor: 'rgb(21,21,21)',
    },
  },
  {
    path: '/bmd-info',
    name: 'bmd-info',
    components: {
      default: AboutBmdView,
    },
    meta: {
      title: i18n.global.t('about.bmd.title'),
      backgroundColor: 'rgb(21,21,21)',
    },
  },
  {
    path: '/profile',
    name: 'user-profile',
    components: {
      default: UserProfilePanelView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('userProfile.pageTitleAccount'),
      backgroundColor: 'rgb(21,21,21)',
    },
  },
  {
    path: '/profile/edit/email',
    name: 'update-user-profile-email',
    components: {
      default: UserProfileUpdateEmailView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('userProfile.pageTitleAccount'),
      backgroundColor: 'rgb(21,21,21)',
    },
  },
  {
    path: '/profile/edit/technician',
    name: 'update-user-profile-technician',
    components: {
      default: UserProfileUpdateTechnicianView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('userProfile.pageTitleAccount'),
      backgroundColor: 'rgb(21,21,21)',
    },
  },
  {
    path: '/profile/edit/phone',
    name: 'update-user-profile-phone',
    components: {
      default: UserProfileUpdatePhoneView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('userProfile.pageTitleAccount'),
      backgroundColor: 'rgb(21,21,21)',
    },
    //beforeEnter: RoleVerifierService.isPhysician,
  },
  {
    path: '/profile/edit/shipping-address',
    name: 'update-user-profile-shipping-address',
    components: {
      default: UserProfileUpdateShippingAddressView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('userProfile.pageTitleAccount'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: RoleVerifierService.isSalesRep,
  },
  {
    path: '/profile/edit/states',
    name: 'update-user-profile-states',
    components: {
      default: UserProfileUpdateStateView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('userProfile.pageTitleAccount'),
      backgroundColor: 'rgb(21,21,21)',
    },
  },
  {
    path: '/profile/edit/password/:id',
    name: 'update-user-profile-password',
    components: {
      default: UserProfileUpdatePasswordView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('userProfile.pageTitleAccount'),
      backgroundColor: 'rgb(21,21,21)',
    },
  },
  {
    path: '/app/:url',
    name: 'app',
    redirect: (props) => {
      window.open(props.params.url as string, '_blank');
      return '';
    },
  },
  {
    path: '/status-error',
    name: 'status-error',
    components: {
      default: StatusErrorView,
    },
    meta: {
      title: i18n.global.t('global.modal.StatusError.metaTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
  },
  {
    path: '/patient/list',
    name: 'patient-list',
    components: {
      default: PatientListView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('patients.pageTitleList'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      const userTierPermissions = useUserTierPermissions();

      if (showComingSoonPage()) {
        return { name: 'coming-soon' };
      }

      if (RoleVerifierService.isInstitutionUser()) {
        if (userTierPermissions.isPatientsMenuBarDisabled.value) {
          return { name: HomePage.STATUS_ERROR };
        }
        return true;
      } else {
        return getUsersHomepage();
      }
    },
  },
  {
    path: '/patient/details/:id',
    name: 'detail-patient',
    components: {
      default: PatientDetailsView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('patients.pageTitleDetails'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      const userTierPermissions = useUserTierPermissions();

      if (showComingSoonPage()) {
        return { name: 'coming-soon' };
      }

      if (RoleVerifierService.isInstitutionUser()) {
        if (userTierPermissions.isPatientFileDisabled.value) {
          return { name: 'patient-list' };
        }
        return true;
      } else {
        return getUsersHomepage();
      }
    },
  },
  {
    path: '/case-list',
    name: '3d-services-list-default',
    components: {
      default: CaseListTechnicianView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('cases.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      if (showComingSoonPage()) {
        return { name: 'coming-soon' };
      }

      if (RoleVerifierService.isTechnicianManager()) {
        return { name: '3d-services-list-manager' };
      } else if (RoleVerifierService.isTechnician()) {
        return { name: '3d-services-list-technician' };
      }
      return getUsersHomepage();
    },
  },
  {
    path: '/case-list/technician',
    name: '3d-services-list-technician',
    components: {
      default: CaseListTechnicianView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('cases.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      if (showComingSoonPage()) {
        return { name: 'coming-soon' };
      }

      if (RoleVerifierService.isTechnician()) {
        return true;
      } else {
        return getUsersHomepage();
      }
    },
  },
  {
    path: '/case-list/manager',
    name: '3d-services-list-manager',
    components: {
      default: CaseListManagerView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('cases.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      if (showComingSoonPage()) {
        return { name: 'coming-soon' };
      }

      if (RoleVerifierService.isTechnicianManager()) {
        return true;
      } else {
        return getUsersHomepage();
      }
    },
  },
  {
    path: '/case/history/:id',
    name: '3d-services-history',
    components: {
      default: CaseHistoryView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('cases.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: (to) => {
      if (showComingSoonPage()) {
        return { name: 'coming-soon' };
      }

      if (
        RoleVerifierService.isTechnicianManager() ||
        RoleVerifierService.isTechnician()
      ) {
        return true;
      } else {
        return getUsersHomepage();
      }
    },
  },
  {
    path: '/calendar',
    name: 'case-calendar',
    components: {
      default: CaseCalendarView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('caseCalendar.pageTitleCalendar'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      const userTierPermissions = useUserTierPermissions();

      if (showComingSoonPage()) {
        return { name: 'coming-soon' };
      }

      if (RoleVerifierService.isInstitutionUser()) {
        if (userTierPermissions.isCalendarMenuBarDisabled.value) {
          return getUsersHomepage();
        }
        return true;
      } else {
        return getUsersHomepage();
      }
    },
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    components: {
      default: ComingSoon,
    },
    meta: {
      title: i18n.global.t('global.comingSoonTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    components: {
      default: AnalyticsView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('analytics.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      return false;
      const userTierPermissions = useUserTierPermissions();

      if (RoleVerifierService.isInstitutionUser()) {
        if (userTierPermissions.isAnalyticsMenuBarDisabled.value) {
          return getUsersHomepage();
        }
        return true;
      } else {
        return getUsersHomepage();
      }
    },
  },
  {
    path: '/mobile/patient/exams/:id',
    name: 'mobile-patient-exams',
    components: {
      default: MobileExamsView,
    },
    meta: {
      title: i18n.global.t('mobile.exams.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
      isMobile: true,
    },
    beforeEnter: () => {
      if (
        RoleVerifierService.isPhysician() ||
        RoleVerifierService.isClinicalStaff() ||
        RoleVerifierService.isSalesRep()
      ) {
        return true;
      } else {
        router.push({
          name: HomePage.STATUS_ERROR,
          query: { [QueryKeys.MobileError]: 'true' },
        });
      }
    },
  },
  {
    path: '/mobile/patient/strategy/:id',
    name: 'mobile-patient-strategy',
    components: {
      default: MobileStrategyView,
    },
    meta: {
      title: i18n.global.t('mobile.strategy.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
      isMobile: true,
    },
    beforeEnter: () => {
      if (
        RoleVerifierService.isPhysician() ||
        RoleVerifierService.isClinicalStaff() ||
        RoleVerifierService.isSalesRep()
      ) {
        return true;
      } else {
        router.push({
          name: HomePage.STATUS_ERROR,
          query: { [QueryKeys.MobileError]: 'true' },
        });
      }
    },
  },
  {
    path: '/orders',
    name: 'rod-ordering',
    components: {
      default: RodOrderingView,
      NavigationBarVerticalView,
    },
    meta: {
      title: i18n.global.t('rodOrdering.pageTitle'),
      backgroundColor: 'rgb(21,21,21)',
    },
    beforeEnter: () => {
      if (RoleVerifierService.isRodManufacturer()) {
        return true;
      } else {
        return getUsersHomepage();
      }
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Prevent non identified user to access the application pages
router.beforeEach(async (to) => {
  document.title = `${APP_NAME} - ${to.meta.title}`;
  if (to.path === '/status-error') {
    return true;
  }

  if (!RoleVerifierService.isUserIdentified()) {
    if (to.path === '/') {
      await AuthenticationService.logout();
      return false;
    }
    return { name: HomePage.STATUS_ERROR };
  }

  if (to.path === '/eula') {
    return true;
  }

  if (StatusVerifierService.isActive() || StatusVerifierService.isNew()) {
    if (!StatusVerifierService.hasAcceptedEULA()) {
      const redirectPage =
        to.path !== '/' ? { [QueryKeys.RedirectToPage]: to.path } : null;
      return {
        name: 'eula',
        query: redirectPage,
      };
    } else {
      return true;
    }
  }

  return { name: HomePage.STATUS_ERROR };
});

function showComingSoonPage() {
  if (
    WorkspaceConfig.instance().isComingSoonEnabled() &&
    !RoleVerifierService.isAdmin()
  ) {
    return true;
  }
  return false;
}

export default router;
