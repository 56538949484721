import { WorkspaceConfig } from '../assets/config';
import { WorkspaceLogger } from '../logger';
import { UserAuthzInfo, NotAGenericError } from '../models';
import { AIEAccess } from '../models/aie-configuration';

import * as UtilsService from './utils-service';
import { useMobileAuthentication } from '../composables/useMobileAuthentication';

const {
  authCookie,
  headers,
  destroy: destroyMobileAuth,
} = useMobileAuthentication();

const AUTHENTICATION_BASE_PATH =
  WorkspaceConfig.instance().getBasePath('AuthenticationSvc');

export async function userinfo(): Promise<UserAuthzInfo> {
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/userinfo`;

  const userInfo: UserAuthzInfo = await fetch(destUrl, {
    headers: headers.value,
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
    });

  return userInfo;
}

export async function refreshSession(): Promise<void> {
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/refresh-session`;
  await fetch(destUrl)
    .then(UtilsService.handleErrors)
    .then((res) => res.ok);

  return;
}

export async function logout(): Promise<void> {
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/logout?redirectUrl=${location.origin}${location.pathname}`;
  sessionStorage.clear();

  if (authCookie.value) destroyMobileAuth();

  window.location.href = destUrl;
}

export async function login(): Promise<void> {
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/login?redirectUrl=${location.origin}${location.pathname}`;
  window.location.href = destUrl;
}

// AIE Connectivity
export async function getClientAccessKey(
  institutionId: string
): Promise<AIEAccess[] | undefined> {
  const queryParams = { institutionId };
  const destUrl = `${
    location.origin
  }/${AUTHENTICATION_BASE_PATH}/api/v1/client-access-key${UtilsService.buildQueryString(
    queryParams
  )}`;

  const response: AIEAccess[] | undefined = await fetch(destUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
    });

  return response;
}

export async function createClientAccessKey(
  institutionId: string
): Promise<AIEAccess[] | undefined> {
  const queryParams = { institutionId };
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/client-access-key`;

  const response: AIEAccess[] | undefined = await fetch(destUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ institutionId }),
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.json())
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
      throw new Error(err);
    });

  return response;
}

export async function deleteClientAccessKey(
  clientAccessKey: string
): Promise<boolean | void> {
  const queryParams = { clientAccessKey };
  const destUrl = `${location.origin}/${AUTHENTICATION_BASE_PATH}/api/v1/client-access-key/${clientAccessKey}`;

  const response: boolean | void = await fetch(destUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(UtilsService.handleErrors)
    .then((res) => res.ok)
    .catch((err) => {
      UtilsService.handleCaughtErrors(err);
    });

  return response;
}
