import { EosDevice } from './institution';

export const UserStatuses = ['New', 'Active', 'Deactivated', 'Reset'] as const;
export type UserStatus = typeof UserStatuses[number];
export const NEW_STATUS = 0;
export const ACTIVE_STATUS = 1;
export const DEACTIVATED_STATUS = 2;
export const RESET_STATUS = 3;

export interface UserAuthzInfo {
  userId: string;
  roles: Array<string>;
}

export interface User extends UserAuthzInfo {
  firstName: string;
  lastName: string;
  institutionEosDevices?: EosDevice[];
  suffix?: string;
  status: UserStatus;
  roleIds: Array<string>;
  lastLoginDate?: number;
  salesForceId?: string;
  telephone?: string;
  emailAddress?: string;
  surgeonConnection?: Array<string>;
  usersConnection?: Array<string>;
  accountState?: Array<string>;
  states?: Array<string>;
  tierFeatures?: Array<string>;
  referringPhysicianTags?: Array<string>;
  institutionName?: string;
  institutionId?: string;
  institutionSalesForceId?: string;
  institutionTier?: number;
  userRoleProperties?: null | {
    subRole?: UserSubroleEnum | string;
    rodSubRole?: RodUserSubroleEnum | string;
    inexperienced?: boolean;
    onlineStatus?: string | OnlineStatusEnum;
    institutionRole?: string;
  };
  shippingAddress?: UserShippingAddress;
  inactivitySeconds?: number;
  eulaAcknowledged?: boolean;
  userPreferences?: UserPreferences;
  isBetaUser: boolean;
}

export enum UserSubroleEnum {
  ATECCustomerService = 'ATECCustomerService',
  ATECMemphis = 'ATECMemphis',
  ATECSupplyChain = 'ATECSupplyChain',
}

export enum RodUserSubroleEnum {
  Modeling = 'Modeling',
  Planning = 'Planning',
  Manager = 'Manager',
}

export interface UserConnection {
  userConnectionId: string;
  parentUserId: string;
  childUserId: string;
  favorite: boolean;
  default: boolean;
}

export interface UserPreferences {
  userPreferencesId?: string;
  userId?: string;
  emailNotifications?: boolean;
  mobileNotifications?: boolean;
  autoAddRodToSurgicalPlan?: boolean;
  pendingSurgicalPlanCompletionNotifications?: boolean;
  pendingSurgicalPlanSchedulingNotifications?: boolean;
  pendingSurgicalPlanApprovalNotifications?: boolean;
  showSurgicalCandidatePatientsOnly?: boolean;
}

export interface UserShippingAddress {
  addressId?: null | string;
  city: null | string;
  country: null | string;
  state: null | string;
  streetAddress: null | string;
  zipCode: null | string;
}

export const USERS_DEFAULT_LIMIT = 5;

export enum OnlineStatusEnum {
  Online = 'Online',
  Offline = 'Offline',
  Paused = 'Paused',
}

export enum UserStatusesEnum {
  New = 'New',
  Active = 'Active',
  Deactivated = 'Deactivated',
  Reset = 'Reset',
}
