<script lang="ts" setup>
import {
  Institution,
  OnlineStatusEnum,
  RoleIdBasedOnRoleName,
  User,
} from '../../../models';
import { computed, defineProps, onMounted, ref, watch } from 'vue';
import { RoleVerifierService } from '../../../services';
import { validateShippingAddress } from '../../../services/shipping-address-service';
import { useStore } from '../../../store';
import SubmenuConnectionStatus from './submenu-connection-status.vue';
import SubmenuConnections from './submenu-connections.vue';
import UserMenuHeader from './user-menu-header.vue';
import UserMenuLogout from './user-menu-logout.vue';
import {
  updateUserOnlineStatus,
  getUsersConnections,
  getInstitution,
  updateUser,
  sendTechnicianHeartbeat,
  clearTechnicianHeartbeat,
} from '../../../services/account-mgt-service';
import SubmenuInstitutions from './submenu-institutions.vue';
import SubmenuStates from './submenu-states.vue';
import SubmenuSurgeons from './submenu-surgeons.vue';
import { useRoute, useRouter } from 'vue-router';
import { useSurgeonFeaturesForNonPhysicianUsers } from '../../../composables/useSurgeonFeaturesForNonPhysicianUsers';
import { useGlobalErrorsStore } from '../../../store/useGlobalErrorsStore';
import { useAnalyticsStore } from '../../../store/useAnalyticsStore';

const globalErrorsStore = useGlobalErrorsStore();
const analyticsStore = useAnalyticsStore();

const { setPhysician, selectedPhysicianUserId } =
  useSurgeonFeaturesForNonPhysicianUsers();

const store = useStore();

interface Props {
  modelValue: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);

const userConnections = ref<Array<User>>([]);
const userInstitution = ref<Institution | undefined>();

const userSurgeons = computed(() => {
  return userConnections.value.filter((user) => {
    return user.roleIds.includes(RoleIdBasedOnRoleName.PHYSICIAN);
  });
});

const userStates = computed(() => {
  return userInfo.value.states?.map((stateAbbr: string) =>
    store.getStateFullName(stateAbbr)
  );
});

const userInfo = computed(() => {
  return store.currentUserInfo;
});

const isOnlineStatusLoading = ref(false);
const isExperiencedStatusLoading = ref(false);

async function handleOnlineStatusChanged(newStatus: OnlineStatusEnum) {
  try {
    isOnlineStatusLoading.value = true;
    const resp = await updateUserOnlineStatus(newStatus);
    if (resp === true) {
      // set heartbeat when changing from offline to online/paused
      if (
        userInfo.value.userRoleProperties?.onlineStatus ===
          OnlineStatusEnum.Offline &&
        (newStatus === OnlineStatusEnum.Online ||
          newStatus === OnlineStatusEnum.Paused)
      ) {
        startHeartbeat();
      }

      await store.getUserInfo();
      isOnlineStatusLoading.value = false;
    }
  } catch (error) {
    globalErrorsStore.showGenericError(error);
  }
}

async function handleExperiencedChanged(newStatus: boolean) {
  isExperiencedStatusLoading.value = true;
  const user = validateShippingAddress(store.currentUserInfo);
  if (!user) return;

  try {
    user.userRoleProperties!.inexperienced = !newStatus;
    await updateUser(store.currentUserInfo);
    isExperiencedStatusLoading.value = false;
  } catch (error) {
    globalErrorsStore.showGenericError(error);
  }
}

onMounted(async () => {
  if (canSeeConnectionsSubmenu.value || canSeeSurgeonsSubmenu.value) {
    try {
      userConnections.value = await getUsersConnections(
        store.currentUserInfo.userId
      );
    } catch (error) {
      globalErrorsStore.showGenericError(error);
    }
  }

  if (store.currentUserInfo.institutionId) {
    try {
      userInstitution.value = await getInstitution(
        store.currentUserInfo.institutionId
      );
    } catch (error) {
      globalErrorsStore.showGenericError(error);
    }
  }

  // set heartbeat if logging in and status is already online/paused
  if (
    userInfo.value.userRoleProperties?.onlineStatus ===
      OnlineStatusEnum.Online ||
    userInfo.value.userRoleProperties?.onlineStatus === OnlineStatusEnum.Paused
  ) {
    startHeartbeat();
  }
});

let heartbeat: number;
function startHeartbeat() {
  sendTechnicianHeartbeat();
  heartbeat = setInterval(() => {
    sendTechnicianHeartbeat();
  }, 60 * 1000); // send every minute
}

function stopHeartbeat() {
  clearTechnicianHeartbeat();
  clearInterval(heartbeat);
}

const canSeeOnlineStatusSubmenu = computed(() => {
  return (
    RoleVerifierService.isTechnician() &&
    !RoleVerifierService.isTechnicianManager()
  );
});

const canSeeConnectionsSubmenu = computed(() => {
  return RoleVerifierService.isPhysician();
});

const canSeeInstitutionsSubmenu = computed(() => {
  return RoleVerifierService.isPhysician();
});

const canSeeSurgeonsSubmenu = computed(() => {
  return (
    RoleVerifierService.isSalesRep() || RoleVerifierService.isClinicalStaff()
  );
});

const canSeeStatesSubmenu = computed(() => {
  return RoleVerifierService.isUserAdmin();
});

const menuOpenCounter = ref(0); // cheesed
function handleBackgroundClick(e: MouseEvent) {
  if (
    userMenu.value &&
    e.target !== userMenu.value &&
    !userMenu.value.contains(e.target as Node)
  ) {
    if (menuOpenCounter.value === 0) {
      menuOpenCounter.value++;
      return;
    }
    emit('update:modelValue', false);
  }
}

const router = useRouter();
const route = useRoute();
async function handleSurgeonClicked(user: User) {
  await setPhysician(user.userId, user.institutionId!);
  analyticsStore.resetFiltersAndClearSessionStorage();
  router.push({
    name: 'patient-list',
  });
}

const userMenu = ref();

watch(
  () => props.modelValue,
  (isOpen) => {
    if (isOpen) {
      document.addEventListener('click', handleBackgroundClick);
    } else {
      document.removeEventListener('click', handleBackgroundClick);
      menuOpenCounter.value = 0;
    }
  }
);
</script>

<template>
  <Transition>
    <div v-if="modelValue" class="user-menu-container" ref="userMenu">
      <UserMenuHeader :user-info="userInfo" />
      <SubmenuConnectionStatus
        v-if="canSeeOnlineStatusSubmenu"
        :user-info="userInfo"
        @online-status-changed="handleOnlineStatusChanged"
        @experience-changed="handleExperiencedChanged"
        :is-online-loading="isOnlineStatusLoading"
        :is-experienced-loading="isExperiencedStatusLoading"
      />
      <SubmenuConnections
        v-if="canSeeConnectionsSubmenu"
        :user-connections="userConnections"
      />
      <SubmenuSurgeons
        v-if="canSeeSurgeonsSubmenu"
        :surgeons="userSurgeons"
        @surgeon-clicked="handleSurgeonClicked"
        :current-physician-id-viewing="selectedPhysicianUserId"
      />
      <SubmenuInstitutions
        v-if="canSeeInstitutionsSubmenu"
        :institution="userInstitution"
      />
      <SubmenuStates v-if="canSeeStatesSubmenu" :states="userStates" />
      <UserMenuLogout
        style="padding-top: 0; padding-bottom: 0; cursor: pointer"
      />
    </div>
  </Transition>
</template>

<style lang="scss">
.user-menu-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.user-menu-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  right: 20px;
  width: 300px;
  background-color: #101e12;
  z-index: 10;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
