import { ErrorWithStatus, QueryKeys, User } from '../models';
import { WorkspaceLogger } from '../logger';
import { useGlobalErrorsStore } from '../store/useGlobalErrorsStore';
import { logout } from './authentication-service';
import { useRouter } from 'vue-router';

export function getUserFullName(pUser: User | undefined | null) {
  return pUser ? `${pUser.firstName} ${pUser.lastName.toUpperCase()}` : '';
}

export function phoneMask(phoneNumber: string): string {
  const x = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return x && x[1]
    ? !x[2]
      ? `(${x[1]})`
      : !x[3]
      ? `(${x[1]}) ${x[2]}`
      : `(${x[1]}) ${x[2]} ${x[3]}`
    : '';
}

export function checkKeypressPhoneInput(event: KeyboardEvent): void {
  const authorizedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  if (!authorizedKeys.includes(event.key)) {
    event.preventDefault();
  }
}

export async function handleErrors(res: Response): Promise<Response> {
  if (!res.ok) {
    const aResponseText = await res.text();
    console.log('res.text', res.text);
    if (aResponseText.length > 0) {
      console.log('ErrorWithStatus 1');
      throw new ErrorWithStatus(aResponseText, res.status);
    } else {
      console.log('ErrorWithStatus 2');
      throw new ErrorWithStatus(`${res.status} ${res.statusText}`, res.status);
    }
  }
  // console.log('handleErrors', res);
  return res;
}

interface ApiError {
  message: string;
  level: string;
  timestamp: string;
}

enum CommonApiErrorMessages {
  UserNotAuthenticated = 'user has not authenticated',
  InternalServerError = '500 Internal Server Error',
  NetworkError = 'NetworkError when attempting to fetch resource.',
  ForbiddenCharacters = 'Forbidden characters detected in request payload',
  NotFound = '404 Not Found',
}

export async function handleCaughtErrors(
  err: ApiError,
  okErrorCodesOrMessages?: Array<number | string | null>
): Promise<void> {
  const globalErrorStore = useGlobalErrorsStore();
  const router = useRouter();
  let message;
  try {
    message = JSON.parse(err.message);
    if (message.hasOwnProperty('errorMessage')) {
      message = message.errorMessage;
    }
  } catch (error) {
    message = err.message ?? err.errorMessage;
  }

  // status checks
  if (message?.status === 400) {
    globalErrorStore.setErrorCode(400);
  } else if (message?.status === 401 || err?.status === 401) {
    globalErrorStore.setErrorCode(401);
    try {
      await router.push({
        name: 'status-error',
        query: { [QueryKeys.ErrorCode]: '401' },
      });
    } catch (error) {
      await logout(); // this should be redirect to status-error but check with abhi
    }
  } else if (message?.status === 500) {
    globalErrorStore.setErrorCode(500);
  }

  // sometimes statuses dont come through, so we check the message as well
  if (message === CommonApiErrorMessages.NetworkError) {
    globalErrorStore.setErrorCode(499);
  } else if (message === CommonApiErrorMessages.UserNotAuthenticated) {
    await logout();
  } else if (message === CommonApiErrorMessages.InternalServerError) {
    globalErrorStore.setErrorCode(500);
  } else if (message === CommonApiErrorMessages.ForbiddenCharacters) {
    globalErrorStore.setErrorCode(999);
  }

  // catch all error
  // note if okErrorCodeOrMessages is array with type `null`, we bypass catch all completely
  if (
    message &&
    !globalErrorStore.isError() &&
    okErrorCodesOrMessages?.every((elem) => elem !== null)
  ) {
    let bypassError = false;
    if (okErrorCodesOrMessages) {
      for (let i = 0; i < okErrorCodesOrMessages.length; i++) {
        if (message === okErrorCodesOrMessages[i]) {
          bypassError = true;
          break;
        }
      }
    }

    if (!bypassError) {
      globalErrorStore.setErrorCode(999);
    } else {
      throw err;
    }
  }

  WorkspaceLogger.instance().log({
    message: message,
    level: 'ERROR',
  });

  throw err;
}

export function buildQueryString(anObject?: {
  [x: string]:
    | string
    | number
    | string[]
    | string[][]
    | Array<string>
    | boolean;
}): string {
  if (anObject !== undefined) {
    const queryString = Object.keys(anObject)
      .map(function (key) {
        if (Array.isArray(anObject[key])) {
          return `${key}=${(anObject[key] as Array<string>).join(`&${key}=`)}`;
        }
        return `${key}=${anObject[key]}`;
      })
      .join('&');
    return `?${queryString}`;
  }
  return '';
}

export function getBrowserLanguage(): string {
  return window.navigator.language;
}
